import React from "react";
import "./services.css";
import AI from "./imgs/AI.png";

import cyber from "./imgs/cyber.png";
import web from "./imgs/webdev.png";
import marketing from "./imgs/marketing.png";
import mobile from "./imgs/mobile.png";
import { Link } from "react-router-dom";

import Aos from "aos";
import "aos/dist/aos.css";

export default function Services() {
  return (
    <>
      <section id="services" data-aos="fade-in">
        <div className="container pt-5">
          <div className="section-heading ">
            <div className="row ">
              <div className="col-12">
                <h3 className="title_block">What We Do.</h3>

                <p>We serve customers of all sizes & backgrounds.</p>
              </div>
            </div>
          </div>
        </div>
        <div className="section-content" data-aos="fade-up">
          <div className="container">
            <div className="row  ">
              <div className="col-lg-6 col-md-6  col-sm-12    ">
                <div className="content-holder">
                  <div className="icon">
                    <img src={cyber} alt="accounting-img"></img>
                  </div>
                  <div className="icon-text">
                    <h4 className="display-4">Cyber Security Solutions</h4>
                    <p className="lead ">
                      We provide solutions according to ISO security
                      standards...
                      <br></br>
                      <Link
                        className="links"
                        to={process.env.PUBLIC_URL + "/" + "Cyber/*"}
                      >
                        Learn More <i className="fa fa-arrow-right fa-1x"></i>
                      </Link>
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-6  col-sm-12    ">
                <div className="content-holder">
                  <div className="icon">
                    <img src={AI} alt="accounting-img"></img>
                  </div>
                  <div className="icon-text">
                    <h4 className="display-4">AI & Data Science</h4>
                    <p className="lead ">
                      We provide the following services reatled to
                      Data-Science...
                      <br></br>
                      <Link
                        className="links"
                        to={process.env.PUBLIC_URL + "/" + "DataScience/*"}
                      >
                        Learn More <i className="fa fa-arrow-right fa-1x"></i>
                      </Link>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="row mt-1  ">
              <div className="col-lg-6 col-md-6  col-sm-12    ">
                <div className="content-holder">
                  <div className="icon">
                    <img src={web} alt="accounting-img"></img>
                  </div>
                  <div className="icon-text">
                    <h4 className="display-4">Web Development</h4>
                    <p className="lead ">
                      We provide professional web design and development
                      services under one roof...
                      <br></br>
                      <Link
                        className="links"
                        to={process.env.PUBLIC_URL + "/" + "WebApp/*"}
                      >
                        Learn More <i className="fa fa-arrow-right fa-1x"></i>
                      </Link>
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-6  col-sm-12    ">
                <div className="content-holder">
                  <div className="icon">
                    <img src={mobile} alt="accounting-img"></img>
                  </div>
                  <div className="icon-text">
                    <h4 className="display-4">Mobile App Development</h4>
                    <p className="lead">
                      We provide native app development services using flutter
                      and node js...
                      <br></br>
                      <Link
                        className="links"
                        to={process.env.PUBLIC_URL + "/" + "MobileApp/*"}
                      >
                        Learn More <i className="fa fa-arrow-right fa-1x"></i>
                      </Link>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="row  ">
              <div className="col-lg-6 col-md-6  col-sm-12    ">
                <div className="content-holder">
                  <div className="icon">
                    <img src={marketing} alt="accounting-img"></img>
                  </div>
                  <div className="icon-text">
                    <h4 className="display-4">SEO & Digital Marketing</h4>
                    <p className="lead">
                      We porvide digital marketing and SEO services for all kind
                      of legal businesses...
                      <br></br>
                      <Link
                        className="links"
                        to={process.env.PUBLIC_URL + "/" + "SEO/*"}
                      >
                        Learn More <i className="fa fa-arrow-right fa-1x"></i>
                      </Link>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
