import React from "react";
import PageHeader from "../Page-Header/Page-Header";
import PageContent from "../Page-Header/PageContent";
import "./services.css";
import { Link } from "react-router-dom";
import headerImg from "./imgs/engineering.png";
import testing from "./imgs/testing.png";
import advisory from "./imgs/advisory.png";
import gdpr from "./imgs/gdpr.png";
import security from "./imgs/security.png"
import Breaker from '../Breaker';
export default function Branding() {
  return (
    <>
      <PageHeader
        title1="Cyber Security Solutions"
        title2="Secure IT Solutions for growing Businesses."
        img={headerImg}
        bgImg={'https://images.unsplash.com/photo-1591775667978-2222e699c77a?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTd8fG1vbml0b3J8ZW58MHx8MHx8&auto=format&fit=crop&w=600&q=60'}

      ></PageHeader>
      <PageContent servicename="Data Science"></PageContent>
       {/*===================UPPER SECTION OF Data Science PAGE============== */}
       <section id="mobileAppPage" className="pt-5">
        <div className="mobileApp-upper">
          <div className="container">
            <p className="boldParagraph">
            We provide cyber security solutions and risk assessment services to prevent cyber attacks 
            and meet compliance objectives. We do our work in this domain according to professional standards.
            </p>
          </div>
        </div>
         {/*===================HOW WE CAN HELP SECTION OF Data Science PAGE============== */}
        <div className="container pt-2">
          <div className="row">
            <div className="col-lg-6 col-12">
            <div className="container pt-2">
      <h3 className="sloganText pt-5 "> HOW <span className="sloganImpt"> WE CAN HELP</span></h3>
    </div>
           <p className="boldParagraph  text-justify" style={{fontSize:"22px" , textAlign:"left"}} >
          We help organizations understand and manage their cyber risk. We can implement a comprehensive cyber security strategy that will enable your company to detect, prevent, and recover from a cyber security breach.
           </p>
            </div>
            <div className="col-lg-6 col-12">
              <img className="mobileChat" src={security} alt="Cyber Security Image" width="100%"/>
            </div>
          </div>
        </div>
        <br></br>  <br></br>
        <p className="boldParagraph">Our Cyber Security services include:</p>
        <div className="container mt-5 p-3">
            <div className="row d-flex justify-content-center">
            <div className="col-lg-3 col-md-4 mb-md-3 mb-lg-0">
                        <div className="banner-features wow fadeIn animated hover-up animated animated" >
                            <img src={testing} alt="" width={100}/>
                            <br></br><p className="paragraphText">Penetration Testing</p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4 mb-md-3 mb-lg-0">
                        <div className="banner-features wow fadeIn animated hover-up animated animated" >
                            <img src={advisory} alt="" width={100}/>
                            <br></br><p className="paragraphText">Risk Advisory</p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4 mb-md-3 mb-lg-0">
                        <div className="banner-features wow fadeIn animated hover-up animated animated" >
                            <img src={gdpr} alt="" width={100}/>
                            <br></br><p className="paragraphText">GDPR Assessments</p>
                        </div>
                    </div>
                 
                  
            </div>
          </div>
      </section>
      <Breaker></Breaker>
      </>
  )
}