import React from "react";
import "./products.css";
import hospitality from "./imgs/hospitality.png";
import retail from "./imgs/retail.png";
import { Link } from "react-router-dom";
export default function Products() {
  return (
    <section className="products" data-aos="fade-up">
      <div className=" ms-0 me-0">
        <div className="row">
          <div
            style={{ backgroundColor: "#20435A", color: "white" }}
            className="col-lg-6 col-md-12 col-sm-12"
          >
            <div className="products-left">
              <div className="section-heading">
                <h3> Products | Impact POS </h3>
                <p style={{ color: "white" }}>
                  Impact IT Solutions presents an Intelligent point of sale
                  system (Impact POS) according to your Business needs.
                </p>
                <span className="decor"></span>
              </div>
            </div>
          </div>
          <div className=" col-lg-6  col-md-12 col-sm-12 ">
            <div className="products-right ">
              <div className="row  d-flex justify-content-center">
                <div className=" col-lg-6 col-md-6 col-12 ">
                  <div className="products-holder ">
                    <div className="card down">
                      <div className="rounded-icon">
                        <img src={retail} alt="retailPOS" />
                      </div>

                      <h4>Retail POS</h4>
                      <p className="text-justify">
                        Impact retail POS platform enables you to sell
                        everywhere and makes retail management a breeze.
                      </p>
                      <Link
                        className="links"
                        to={process.env.PUBLIC_URL + "/" + "IPOS/*"}
                      >
                        Learn More <i className="fa fa-arrow-right fa-1x"></i>
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="  col-lg-6 col-md-6 col-12">
                  <div className="products-holder">
                    <div className="card">
                      <div className="rounded-icon">
                        <img src={hospitality} alt="retailPOS" />
                      </div>
                      <h4>Hospitality POS</h4>
                      <p className="text-justify">
                        Whether you run a bar, restaurant, takeout, or shop, get
                        it working smarter with our complete POS system.
                      </p>
                      <Link
                        className="links"
                        to={process.env.PUBLIC_URL + "/" + "IPOS/*"}
                      >
                        Learn More <i className="fa fa-arrow-right fa-1x"></i>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      
    </section>
    
  );
}
