import React from 'react'
import Aos from "aos";
import "aos/dist/aos.css";
import { useEffect } from 'react';
import logo from "./logo.png";
import "./assets/css/style.css";
import "./assets/css/responsive.css";
import Navbar from "./components/Navbar/Navbar";
import Footer from "./components/Footer/Footer";
import Body from "./components/Body";
import OurProjects from "./components/POS/OurProjects";
import Contact from "./components/Contact/Contact";
import ScrollToTop from "./ScrollToTop";
import "./App.css";
import AboutMain from "./components/About/AboutMain";
import { BrowserRouter, Routes, Route, Link } from "react-router-dom";
import OurServices from "./components/Services/OurServices";
import Products from "./components/Products/OurProducts";
import Clients from "./components/partners/Partners";
import MobileApp from "./components/Services/MobileApp";
import WebApp from "./components/Services/WebApp";
import Software from "./components/Services/Software";
import Cyber from "./components/Services/Cyber";
import SEO from "./components/Services/SEO";
import DataScience from "./components/Services/DataScience";
import IPOS from './components/Products/IPOS/IPOS';
import IPayroll from './components/Products/IPayRoll/IPayRoll';
import LMS from './components/Products/LMS/LMS';






function App() {
  useEffect(() => {
    Aos.init({
      duration: 1000,
      easing: 'ease-out'
    });
  }, []);
  return (
    <>
      <BrowserRouter>
        <Navbar></Navbar>
        <ScrollToTop>
          <Routes>
            <Route
              path={process.env.PUBLIC_URL + "/"}
              element={<Body />}
            />
            <Route
              path={process.env.PUBLIC_URL + "/" + "OurProjects/*"}
              element={<OurProjects />}
            />
            <Route
              path={process.env.PUBLIC_URL + "/" + "AboutUs/*"}
              element={<AboutMain />}
            />

            <Route
              path={process.env.PUBLIC_URL + "/"  + "Contact/*"}
              element={<Contact />}
            />
             <Route
              path={process.env.PUBLIC_URL + "/"  + "MobileApp/*"}
              element={<MobileApp />}
            />
             <Route
              path={process.env.PUBLIC_URL + "/"  + "WebApp/*"}
              element={<WebApp />}
            />
             <Route
              path={process.env.PUBLIC_URL + "/"  + "Software/*"}
              element={<Software />}
            />
             <Route
              path={process.env.PUBLIC_URL + "/"  + "SEO/*"}
              element={<SEO />}
            />
             <Route
              path={process.env.PUBLIC_URL + "/"  + "DataScience/*"}
              element={<DataScience />}
            />
            <Route
              path={process.env.PUBLIC_URL + "/"  + "Cyber/*"}
              element={<Cyber />}
            />
            
            <Route
              path={process.env.PUBLIC_URL + "/" + "OurServices/*"}
              element={<OurServices />}
            />
            <Route
              path={process.env.PUBLIC_URL + "/" + "OurProducts/*"}
              element={<Products />}
            />
             <Route
              path={process.env.PUBLIC_URL + "/" + "IPOS/*"}
              element={<IPOS />}
            />
            <Route
              path={process.env.PUBLIC_URL + "/" + "IPayroll/*"}
              element={<IPayroll />}
            />
             <Route
              path={process.env.PUBLIC_URL + "/" + "LMS/*"}
              element={<LMS />}
            />

            <Route
              path={process.env.PUBLIC_URL + "/" + "Clients/*"}
              element={<Clients />}
            />
          </Routes>
        </ScrollToTop>
        <Footer></Footer>
      </BrowserRouter>
    </>
  );
 
}


export default App;
window.addEventListener('load', Aos.refresh)