import React from "react";
import Breaker from "../../Breaker";
import PageHeader from '../../Page-Header/Page-Header';
import PageContent from '../../Page-Header/PageContent';
import coverProduct2 from "../../Products/imgs/payroll.png";

export default function IPayroll() {
  return (
    <>
      <section id="pos-section">
      <PageHeader
        title1="Impact Pay-Roll Application"
        title2="A Human Resource Managment System "
       
        bgImg={'https://images.unsplash.com/photo-1591775667978-2222e699c77a?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTd8fG1vbml0b3J8ZW58MHx8MHx8&auto=format&fit=crop&w=600&q=60'}
      ></PageHeader>
      <PageContent servicename="Products"></PageContent>
      <section id="mobileAppPage" className="pt-5">
          <div className="mobileApp-upper">
            <div className="container">
              <p className="boldParagraph">
                I-PayRoll is a management system developed for both employee and admin sides to manage several tasks. It allows real time tracking and management  of employee activites like attendence and payroll.
              </p>
            </div>
          </div>
          {/*===================HOW WE CAN HELP SECTION OF MOBILE APP DEVELOPMENT PAGE============== */}
          <div className="container pt-5">
            <div className="row">
              <div className="col-lg-6 col-12">
                <div className="container ">
                  <h3 className="sloganText  ">
                    Main  <span className="sloganImpt">Features</span>
                  </h3>
                </div>
                <p
                  className="boldParagraph   pt-2"
                  style={{ fontSize: "18px",   }}
                >
                  The software provides support at two ends for both admin and employees. It's main features includes:
                  <ul className="ms-3 mt-3 text-justify">
                    <li><strong>Admin Side</strong></li>
                   <ol className="ms-1 mt-2">
                   <li >Employee Management</li>
                    <li >Employee Directory</li>
                    <li >Payroll Managment</li>
                    <li >Employee Daily Report</li>
                   </ol>
                  </ul>
                  <ul className="ms-3 mt-3 text-justify">
                  <li><strong>Employee Side</strong></li>
                   <ol className="ms-1 mt-2">
                   <li>Mark Attendence</li>
                   <li>View Attendence Details</li>
                   <li>Apply for leave Application</li>
                   <li>Check status of leave approval</li>
                   <li>Payroll</li>
                   </ol>
                   
                  </ul>
                </p>
              </div>
              <div className="col-lg-6 col-12 ">
                <img
                  className="mobileChat pt-5"
                 src={coverProduct2}
                 width="100%"
                  alt="payroll management software image"
                />
              </div>
            </div>
          </div>
    </section>
        <Breaker></Breaker>
      </section>
    </>
  );
}
