import React from "react";
import { useState } from "react";
import logo from "../../assets/imgs/logoBlack.png";
import { Link } from "react-router-dom";
import "./navbar.css";

export default function Navbar() {
  const [isNavExpanded, setIsNavExpanded] = useState(false);
  const [isCustomNav, setIsCustomNav] = useState(false);
  return (
    <>
      <div className="custom-nav">
        <a
          href="#"
          className="menu-toggle text "
          onClick={showExpandedMenu}
          id="show"
        >
          Menu<br></br>
          <span className="hamburger-bars">
            <i className="fa fa-bars fa-2x ms-2"></i>
          </span>
        </a>
        <a
          href="#"
          className="menu-toggle text "
          onClick={hideExpandedMenu}
          id="hide"
          style={{ display: "none" }}
        >
          Close
          <br></br>
          <span className="hamburger-cross">
            <i className="fa fa-xmark fa-2x ms-3"></i>
          </span>
        </a>
      </div>

      <div className={isCustomNav ? "customNav expanded" : "customNav"}></div>
      <div className="wrapper" style={{ display: "none" }}>
        <div className="hidden-nav animate__animated animate__fadeInLeft">
          <div className="h-100 w-100 d-flex flex-column justify-content-center position-relative">
            <div className="menu animate__animated animate__fadeInUp">
              <div className="row ">
                <div className="top-section  ">
                  <ul
                    className="social float-start"
                    style={{ listStyle: "none", display: "flex" }}
                  >
                    <li>
                      <a href="https://www.facebook.com/ImpactITSols">
                        <i className="fa-brands fa-facebook-f"></i>
                      </a>
                    </li>
                    <li>
                      <a href="https://www.linkedin.com/company/impact-it-sols/">
                        <i className="fa-brands fa-linkedin ms-2"></i>
                      </a>
                    </li>
                  </ul>

                  <Link
                    to={process.env.PUBLIC_URL + "/" + "Contact/*"}
                    onClick={(e) => {
                      this.handleEntailmentRequest(e);
                    }}
                    className="btn btn-primary float-end"
                  >
                    Contact
                  </Link>
                </div>
              </div>

              <div className="middle-section">
                <div className="row">
                  <div className="col-lg-2 col-md-2 col-sm-12">
                    <Link
                      to={process.env.PUBLIC_URL + "/"}
                      className="nav-link "
                      aria-current="page"
                      onClick={(e) => {
                        this.handleEntailmentRequest(e);
                      }}
                    >
                      Home
                    </Link>
                  </div>
                  <div className="col-lg-2 col-md-2 col-sm-12">
                    <Link
                      to={process.env.PUBLIC_URL + "/" + "AboutUs/*"}
                      className="nav-link "
                      aria-current="page"
                      onClick={(e) => {
                        this.handleEntailmentRequest(e);
                      }}
                    >
                      About us
                    </Link>
                  </div>

                  <div className="col-lg-2 col-md-2 col-sm-12">
                    <Link
                      to={process.env.PUBLIC_URL + "/" + "OurProducts/*"}
                      className="nav-link "
                      aria-current="page"
                      onClick={(e) => {
                        this.handleEntailmentRequest(e);
                      }}
                    >
                      Products
                    </Link>
                  </div>
                  <div className="col-lg-2 col-md-2 col-sm-12">
                    <Link
                      to={process.env.PUBLIC_URL + "/" + "OurProjects/*"}
                      className="nav-link "
                      aria-current="page"
                      onClick={(e) => {
                        this.handleEntailmentRequest(e);
                      }}
                    >
                      Projects
                    </Link>
                  </div>
                  <div className="col-lg-2 col-md-2 col-sm-12">
                    <Link
                      to={process.env.PUBLIC_URL + "/" + "OurServices/*"}
                      className="nav-link "
                      aria-current="page"
                      onClick={(e) => {
                        this.handleEntailmentRequest(e);
                      }}
                    >
                      Services
                    </Link>
                  </div>
                </div>
              </div>

              <div className="row mt-5" data-aos="fade-left">
                <div className="col-12 d-flex justify-content-center">
                  <div className=" logo "></div>
                  <div className="logo-nav text-center">
                    <a href="#">
                      <img src={logo} className="rounded" width={"30%"} />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <nav className="navigation">
        <div className="row">
          <div className="col-6">
            <div className="hidden-nav-small">
              <div id="mySidenav" className="sidenav">
                <a href="#" className="closebtn link" onClick={closeNav}>
                  &times;
                </a>

                <Link to={process.env.PUBLIC_URL + "/"}

                  aria-current="page"
                  onClick={(e) => {
                    this.handleEntailmentRequest(e);
                  }} className="link">  Home
                </Link>


                <Link to={process.env.PUBLIC_URL + "/" + "AboutUs/*"} aria-current="page"
                  onClick={(e) => {
                    this.handleEntailmentRequest(e);
                  }} className="link">About us
                </Link>

                <Link to={process.env.PUBLIC_URL + "/" + "Contact/*"} aria-current="page"
                  onClick={(e) => {
                    this.handleEntailmentRequest(e);
                  }} className="link"> Contact us
                </Link>
                <Link to={process.env.PUBLIC_URL + "/" + "OurProjects/*"} aria-current="page"
                  onClick={(e) => {
                    this.handleEntailmentRequest(e);
                  }} className="link">
                  Projects
                </Link>
                <Link aria-current="page"
                  to={process.env.PUBLIC_URL + "/" + "OurServices/*"}
                  onClick={(e) => {
                    this.handleEntailmentRequest(e);
                  }} className="link">
                  Our Services
                </Link>

                <Link aria-current="page"
                  to={process.env.PUBLIC_URL + "/" + "OurProducts/*"}
                  onClick={(e) => {
                    this.handleEntailmentRequest(e);
                  }} className="link">
                  Our Products
                </Link>
              </div>

              <br></br>
              <span
                style={{
                  fontSize: "22px",
                  cursor: "pointer",
                  marginLeft: "10px",
                }}
                onClick={openNav}
              >
                &#9776; MENU
              </span>
            </div>
          </div>
          <div className="col-6">
            <a className="brand-name float-right" href="#">
              <img src={logo} className="rounded" width="150px" height="80" />
            </a>
          </div>
        </div>
      </nav>
    </>
  );
}
function showExpandedMenu() {
  let text = "Hello Dolly.";

  document.getElementsByClassName("wrapper")[0].style.display = "block";
  document.getElementById("hide").style.display = "block";
  document.getElementById("show").style.display = "none";
}
function hideExpandedMenu() {
  let text = "Hello Dolly.";

  document.getElementsByClassName("wrapper")[0].style.display = "none";
  document.getElementById("hide").style.display = "none";
  document.getElementById("show").style.display = "block";
}

function refreshPage() {
  window.location.reload(false);
}
function openNav() {
  document.getElementById("mySidenav").style.width = "250px";
}

function closeNav() {
  document.getElementById("mySidenav").style.width = "0";
}
