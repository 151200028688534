import React from 'react';
import Header from './Header/header';
import Services from './Services/Services';
import About from './About/About';

import Products from './Products/Products';
import Partners from './partners/Partners';
import { Link } from "react-router-dom";
import breaker from './Contact/imgs/breaker.png';
import Breaker from './Breaker';
export default function Body() {
  return (
    <>
   <section className='content-body' >
   <Header></Header>
   
  
   <Services></Services>
   
  {/**/}
   <About></About>

   <Products></Products>
   <Partners></Partners>
   
  <Breaker></Breaker>
  

   </section>
   
   

   
   

</>
  )
}
