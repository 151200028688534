import React from "react";
import PageHeader from "../Page-Header/Page-Header";
import PageContent from "../Page-Header/PageContent";
import "./services.css";
import { Link } from "react-router-dom";
import Breaker from "../Breaker";
import headerImg from "./imgs/engineering.png";
import mobileChat from "./imgs/mobileChat.png";
import algo from "./imgs/algorithm.png";
import flutter from "./imgs/flutter-icon.png";
import nativeApp from "./imgs/native-app.png";
export default function MobileApp() {
  return (
    <>
      <PageHeader
        title1="Mobile App Development"
        title2="IT Solutions for growing businesses."
        img={headerImg}
        bgImg={'https://images.unsplash.com/photo-1591775667978-2222e699c77a?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTd8fG1vbml0b3J8ZW58MHx8MHx8&auto=format&fit=crop&w=600&q=60'}

      ></PageHeader>
      <PageContent servicename="Mobile App Development"></PageContent>

      {/*===================UPPER SECTION OF MOBILE APP DEVELOPMENT PAGE============== */}
      <section id="mobileAppPage" className="pt-5">
        <div className="mobileApp-upper">
          <div className="container">
            <p className="boldParagraph">
              We create enterprise level mobile-applications that can run
              smoothly on every plateform you prefer.
            </p>
          </div>
        </div>
         {/*===================HOW WE CAN HELP SECTION OF MOBILE APP DEVELOPMENT PAGE============== */}
        <div className="container pt-5">
          <div className="row">
            <div className="col-lg-6 col-12">
            <div className="container pt-5">
      <h3 className="sloganText pt-5 ">HOW  <span className="sloganImpt">WE CAN HELP</span></h3>
    </div>
           <p className="boldParagraph  text-justify" style={{fontSize:"22px" , textAlign:"left"}} >
       Our expert developers  cover entire cycle of mobile-application development. From idea to launch we can take your idea to next level. We also porvide optimization and scale-up services of the application developed by our team.
           </p>
            </div>
            <div className="col-lg-6 col-12">
              <img className="mobileChat" src={mobileChat} alt="Mobile Application Image" width="70%"/>
            </div>
          </div>
        </div>
        <p className="boldParagraph">Our Mobile Application Development services include:</p>
        <div className="container mt-5 p-3">
            <div className="row d-flex justify-content-center">
            <div className="col-lg-3 col-md-4 mb-md-3 mb-lg-0">
                        <div className="banner-features wow fadeIn animated hover-up animated animated" >
                            <img src={algo} alt="" width={80}/>
                            <br></br><p className="paragraphText">Intelligent Algorithms and modern Design</p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4 mb-md-3 mb-lg-0">
                        <div className="banner-features wow fadeIn animated hover-up animated animated" >
                            <img src={nativeApp} alt="" width={80}/>
                            <br></br><p className="paragraphText">Design & Development of cross-plateform and native mobile applications</p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4 mb-md-3 mb-lg-0">
                        <div className="banner-features wow fadeIn animated hover-up animated animated" >
                            <img src={flutter} alt="" width={170}/>
                            <br></br><p className="paragraphText">Application Development based on Flutter & Node JS</p>
                        </div>
                    </div>
                 
                  
            </div>
          </div>
      </section>
    
      <Breaker></Breaker>
    </>
  );
}
