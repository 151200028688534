import React from "react";
import PageHeader from "../Page-Header/Page-Header";
import PageContent from "../Page-Header/PageContent";
import "./services.css";
import { Link } from "react-router-dom";
import bg from "./imgs/ICT.png";
import ContentLeft from "../POS/body/ContentLeft";
import ContentRight from "../POS/body/ContentRight";
import web from "./imgs/web.jpg";
import mobile from "./imgs/mobile.jpg";
import desktop from "./imgs/desktop.jpg";
import headerImg from "./imgs/engineering.png";
export default function ServicesMain() {
  return (
    <>
      <PageHeader
        title1="Our Services"
        title2="IT Solutions for growing businesses."
        img={headerImg}
        bgImg={
          "https://images.unsplash.com/photo-1550745165-9bc0b252726f?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mzd8fGNvbXB1dGVyfGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=600&q=60https://images.unsplash.com/photo-1550745165-9bc0b252726f?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mzd8fGNvbXB1dGVyfGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=600&q=60"
        }
      ></PageHeader>
      <PageContent servicename="Services"></PageContent>

      <section id="services-inner" className=''> 
        <div className="row  text-center d-flex justify-content-center">
          <div className="col-md-12">
            <h3 className="font-weight-bold  heading_h2 text-capitalize">
              Impact's Design &amp; Development Services
            </h3>
            <p className="lead">
              We at Impact IT Solutions provide the following IT Services.
              Contact us regarding any service you require.
            </p>
          </div>
        </div>
        <br></br>
        <div
          className="container "
          data-aos="fade-up"
        >
          <div className="row text-center  d-flex justify-content-center" data-aos="fade-up">
            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12 mb-4   animate__animated animate__fadeInUp">
              <div className=" border-0 bg-white service-card">
                <img
                  src="https://cdn-icons-png.flaticon.com/128/4290/4290002.png"
                  width={60}
                />
                <h3 className="color_blue">Cyber Security Solutions</h3>
                <p>
                  We are Offering world-class cyber-security solutions to
                  safeguard your digital assets and help protect your
                  organization against cyber attacks, which can result in theft
                  of sensitive data, damage to reputation and other severe
                  consequences.
                </p>
                <div className="card-footer">
                  <Link
                    className="links"
                    to={process.env.PUBLIC_URL + "/" + "Cyber/*"}
                  >
                    Learn More <i className="fa fa-arrow-right fa-1x"></i>
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12 mb-4   animate__animated animate__fadeInUp">
              <div className=" border-0 bg-white service-card">
                <img
                  src="https://cdn-icons-png.flaticon.com/128/1312/1312313.png"
                  width={60}
                />
                <h3 className="color_blue">AI & Data Science</h3>
                <p>
                  We provide smart solutions using Ariticial Intelligence. Our
                  data analysts provide services related to data cleaning ,
                  mining and presentation according to your business.
                </p>
                <div className="card-footer">
                  <Link
                    className="links"
                    to={process.env.PUBLIC_URL + "/" + "DataScience/*"}
                  >
                    Learn More <i className="fa fa-arrow-right fa-1x"></i>
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12 mb-4   animate__animated animate__fadeInUp">
              <div className=" border-0 bg-white service-card">
                <img
                  src="https://cdn-icons-png.flaticon.com/128/2200/2200177.png"
                  width={60}
                />
                <h3 className="color_blue">SEO/Digital Marketing</h3>
                <p>
                  Let Google find you. Our team of software developers can help
                  you optimize your website seo and do digital marketing of your
                  brand so that your business can target the right audience.
                </p>
                <div className="card-footer">
                  <Link
                    className="links"
                    to={process.env.PUBLIC_URL + "/" + "SEO/*"}
                  >
                    Learn More <i className="fa fa-arrow-right fa-1x"></i>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="container "
          data-aos="fade-up"
        >
        <div className="row d-flex justify-content-center" data-aos="fade-up">
          <div className="col-xl-4 col-lg-5 col-md-6 col-sm-12 col-12 mb-4   animate__animated animate__fadeInUp">
            <div className=" border-0 bg-white service-card">
              <img
                src="https://cdn-icons-png.flaticon.com/128/2282/2282188.png"
                width={70}
              />
              <h3 className="color_blue">Web Development</h3>
              <p>
                We believe that webiste is the identity of a business in the
                internet world. By keeping this in mind, we not only build
                beautiful but also mobile first design for webistes that can
                grow your business and attract more clients. This process
                includes requirement gathering, wireframing, web designing, web
                development, testing and launching
              </p>
              <div className="card-footer">
                <Link
                  className="links"
                  to={process.env.PUBLIC_URL + "/" + "WebApp/*"}
                >
                  Learn More <i className="fa fa-arrow-right fa-1x"></i>
                </Link>
              </div>
            </div>
          </div>
          <div className="col-xl-4 col-lg-5 col-md-6 col-sm-12 col-12 mb-4   animate__animated animate__fadeInUp">
            <div className=" border-0 bg-white service-card">
              <img src="https://www.dynamologic.com/wp-content/themes/dynamologic2021/images/service-icon2.webp" />
              <h3 className="color_blue">Mobile App Development</h3>
              <p className="">
                Our mobile app developers can build high-quality native apps for
                both Android and iOS systems aligned with your business and
                security requirements. Our focus is not just application
                development but we also believe in providing end-to-end high
                quality services.
              </p>
              <div className="card-footer">
                <Link
                  className="links"
                  to={process.env.PUBLIC_URL + "/" + "MobileApp/*"}
                >
                  Learn More <i className="fa fa-arrow-right fa-1x"></i>
                </Link>
              </div>
            </div>
          </div>
        </div>
</div>
        <div className="container mt-5 p-3" data-aos="fade-up">
          <div className="row d-flex justify-content-center">
            <div className="col-lg-3 col-md-6 mb-md-3 mb-lg-0">
              <div className="banner-features wow fadeIn animated hover-up animated animated">
                <img
                  src="https://cdn-icons-png.flaticon.com/128/5735/5735108.png"
                  alt=""
                  width={80}
                />
                <br></br>
                <h4 className="bg-1 mt-3">High Quality Work</h4>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 mb-md-3 mb-lg-0">
              <div className="banner-features wow fadeIn animated hover-up animated animated">
                <img
                  src="https://cdn-icons-png.flaticon.com/128/1716/1716506.png"
                  alt=""
                  width={80}
                />
                <br></br>
                <h4 className="bg-1 mt-3">Elegant Design</h4>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 mb-md-3 mb-lg-0">
              <div className="banner-features wow fadeIn animated hover-up animated animated">
                <img
                  src="https://cdn-icons-png.flaticon.com/128/9123/9123385.png"
                  alt=""
                  width={80}
                />
                <br></br>
                <h4 className="bg-1 mt-3">Smooth Execution</h4>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 mb-md-3 mb-lg-0">
              <div className="banner-features wow fadeIn animated hover-up animated animated">
                <img
                  src="https://cdn-icons-png.flaticon.com/128/3247/3247054.png"
                  alt=""
                  width={80}
                />
                <br></br>
                <h4 className="bg-1 mt-3">Customer Support</h4>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
