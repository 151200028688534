import React from "react";
import { Link } from "react-router-dom";
import "./about.css";

export default function About() {
  return (
    <>
      <section id="about" className=" "  data-aos="fade-up">
       
          <div className="section-content d-flex justify-content-center">
          <div className="container">
            <div className="row  g-0">
              <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 mt-5" 
             >
                <div className="left">
                  <div className="img-holder ms-0 ">
                   <p>Our focus is on <br></br> user experience</p>
                  </div>
                </div>
              </div>

              <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12" >
                <div className="right " >
                  <div className="section-heading  ">
                    <h4 className="title_block">ABOUT US</h4>
                    <span></span>
                  </div>
                  <div className="right-content">
                    <div className="container">
                      <p>
                        Impact IT Solutions was established in 2017 and since then it 
                        has been providing IT services to private customers as
                        well as businesses. Our dedicated team is always ready
                        for support and provide on-call services.
                      
                      </p>
                      
                    </div>
                    <div className="header-btn d-flex justify-content-center">
                    <Link className="links" to={process.env.PUBLIC_URL + '/' + 'AboutUs/*' } >
                    Learn More <i className="fa fa-arrow-right fa-1x"></i>
                  </Link>
                
                       
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
