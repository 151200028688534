import React from "react";
import PageHeader from "../Page-Header/Page-Header";
import PageContent from "../Page-Header/PageContent";
import "./services.css";
import { Link } from "react-router-dom";
import seo from "./imgs/seo.png";

import onpage from "./imgs/onPage.png";
import offpage from './imgs/offPage.png';
import marketing from "./imgs/marketing2.png";
import Breaker from "../Breaker";
import headerImg from "./imgs/engineering.png";

export default function SEO() {
  return (
    <>
      <PageHeader
        title1="Search Engine Optimization"
        title2="Let Google find you..."
        img={headerImg}
        bgImg={'https://images.unsplash.com/photo-1591775667978-2222e699c77a?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTd8fG1vbml0b3J8ZW58MHx8MHx8&auto=format&fit=crop&w=600&q=60'}

      ></PageHeader>
      <PageContent servicename="SEO"></PageContent>
       {/*===================UPPER SECTION OF Data Science PAGE============== */}
       <section id="mobileAppPage" className="pt-5">
        <div className="mobileApp-upper">
          <div className="container">
            <p className="boldParagraph">
            Search Engine Optimization  is the process of optimizing a website and its content to imporve its rankings in search engines such as Google and BING. By improving its ranking SEO actually allow more visitors to visit a webiste.
            </p>
          </div>
        </div>
        {/*===================HOW WE CAN HELP SECTION OF Data Science PAGE============== */}
        <div className="container pt-2">
          <div className="row">
            <div className="col-lg-6 col-12">
              <div className="container pt-2">
                <h3 className="sloganText pt-5 ">
                  {" "}
                  WE CAN HELP <span className="sloganImpt">IN</span>
                </h3>
              </div>
              <p
                className="boldParagraph  text-justify"
                style={{ fontSize: "22px", textAlign: "left" }}
              >
               Our SEO Analysts help you in optimizing your website by implementing different kinds of SEO techniques. These techniques will allow search engines to find and rank your company's website and can attract more visitors
              </p>
            </div>
            <div className="col-lg-6 col-12">
              <img
                className="mobileChat"
                src={seo}
                alt="Mobile Application Image"
                width="70%"
              />
            </div>
          </div>
        </div>
        <br></br> <br></br>
        <p className="boldParagraph">Our SEO services include:</p>
        <div className="container mt-5 p-3">
          <div className="row d-flex justify-content-center">
            <div className="col-lg-3 col-md-4 mb-md-3 mb-lg-0">
              <div className="banner-features wow fadeIn animated hover-up animated animated">
                <img src={onpage} alt="" width={100} />
                <br></br>
                <p className="paragraphText">On-Page SEO</p>
              </div>
            </div>
            <div className="col-lg-3 col-md-4 mb-md-3 mb-lg-0">
              <div className="banner-features wow fadeIn animated hover-up animated animated">
                <img src={offpage} alt="" width={100} />
                <br></br>
                <p className="paragraphText">Off-Page SEO</p>
              </div>
            </div>
            <div className="col-lg-3 col-md-4 mb-md-3 mb-lg-0">
              <div className="banner-features wow fadeIn animated hover-up animated animated">
                <img src={marketing} alt="" width={100} />
                <br></br>
                <p className="paragraphText">SEO Marketing</p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Breaker></Breaker>
      </>
  )
}