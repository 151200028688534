import React from "react";
import "./header.css";
import logo from "../../assets/imgs/logo.png";
import banner from "./imgs/img2.svg";
import { Link } from "react-router-dom";
import network from "../Page-Header/imgs/cube.webp";

export default function header() {
  return (
    <>
      <section className="header h-100 "  >
        <div className="container">
          

          <div className="header-holder  " >
         
            <div className="row  mt-5">
              
              <div className="col-lg-12 mt-4 col-md-12 col-sm-12 col-xs-12 d-flex justify-content-center" data-aos="fade-right">
                <div className="header-left mt-5 ms-5">
              
                  <br></br>
                  <div className="header-logo  " data-aos="fade-left">
              <img className="" src={logo} />
            </div>
                  <h1 className=""  >We are building <span className="impt">Softwares </span>to help</h1>

                  <p>Providing professional IT services since 2017.</p>
                
                  <div className="header-btn"  data-aos="fade-left">
                    <Link to={ process.env.PUBLIC_URL + '/' + 'IPOS/*'} className="btn btn-primary">
                      Impact POS <i className="fa fa-arrow-right fa-1x"></i>
                    </Link>
                    <Link to={ process.env.PUBLIC_URL + '/' + 'OurServices/*'} className="ms-lg-2 btn btn-primary ">
                      Services <i className="fa fa-arrow-right fa-1x"></i>
                    </Link>
                    
                  </div>
                
                 
                </div>
              </div>
              
            </div>
          </div>
         
        </div>
        
      </section>
    </>
  );
}
