import React from "react";
import PageHeader from "../../Page-Header/Page-Header";
import PageContent from "../../Page-Header/PageContent";
import Breaker from "../../Breaker";
import coverProduct1 from "../imgs/POS.png";
import selfOrdering from "./imgs/self-ordering.jpg";
import secured from "./imgs/secured.png";
import IPOSItems from "./IPOSFeatures/IPOSItems";
import recom from "./imgs/recom.png";
import crm from "./imgs/crm.png";
import reports from "./imgs/reports.png";
import stock from "./imgs/stock.png";
import display from "./imgs/display.jpg";
import { data } from "./data";
import "./IPOS.css";
export default function IPOS() {
  return (
    <>
      <section id="pos-section">
        <PageHeader
          title1="Impact-Point of Sale"
          title2="A smart POS solution for Retail Business"
          bgImg={
            "https://images.unsplash.com/photo-1591775667978-2222e699c77a?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTd8fG1vbml0b3J8ZW58MHx8MHx8&auto=format&fit=crop&w=600&q=60"
          }
        ></PageHeader>
        <PageContent servicename="Products"></PageContent>
        <section id="mobileAppPage" className="pt-5">
          <div className="mobileApp-upper">
            <div className="container">
              <p className="boldParagraph">
                Impact POS is a smart point of sale application which provides
                smooth functionality on multiple plateforms. It is an
                intelligent point of sale system according to retail business
                needs.
              </p>
            </div>
          </div>
          {/*===================HOW WE CAN HELP SECTION OF MOBILE APP DEVELOPMENT PAGE============== */}
          <div className="container pt-3">
            <div className="row">
              <div className="col-lg-6 col-12 g-0">
                <div className="container ">
                  <h3 className="sloganText  ">
                    Main <span className="sloganImpt">Features</span>
                  </h3>
                </div>
                <p
                  className="boldParagraph  pt-5"
                  style={{ fontSize: "18px", float:"left" }}
                >
                  The main features of IPOS are mentioned below:
                  <ul className="ms-3 mt-3 text-justify">
                    <li>Table Self Ordering</li>
                    <li>Secured Payment Integration </li>
                    <li>Smart Recommendation System</li>
                    <li>Loyality Program CRM</li>
                    <li>Kitchen Display System</li>
                    <li>Effective Stock Controlling</li>
                    <li>Real-time Report Generation</li>
                  </ul>
                </p>
              </div>
              <div className="col-lg-6 col-12">
                <img
                  className="mobileChat"
                  src={coverProduct1}
                  width="80%"
                  alt="IPOS APP Image"
                />
              </div>
            </div>
          </div>

          <div className="container ">
            <h3 className="sloganText  ">
              Detailed <span className="sloganImpt">Features</span>
            </h3>
          </div>
          <div className="container">
            <IPOSItems
              img={selfOrdering}
              title="Table Self Ordering"
              clr="#77aecc"
              txt={data[0].txt}
            ></IPOSItems>
            <IPOSItems
              img={secured}
              title="Secured Payment Integration"
              clr="#4185B2"
              txt={data[1].txt}
            ></IPOSItems>
            <IPOSItems
              img={recom}
              title="Smart Recommendation System"
              clr="#77aecc"
              txt={data[2].txt}
            ></IPOSItems>
            <IPOSItems
              img={crm}
              title="Loyality Program | CRM"
              clr="#4185B2"
              txt={data[3].txt}
            ></IPOSItems>
            <IPOSItems
              img={display}
              title="Kitchen Display System"
              clr="#77aecc"
              txt={data[4].txt}
            ></IPOSItems>
            <IPOSItems
              img={stock}
              title="Effective Stock Controlling"
              clr="#4185B2"
              txt={data[5].txt}
            ></IPOSItems>
            <IPOSItems
              img={reports}
              title="Real-time Report Generation"
              clr="#77aecc"
              txt={data[6].txt}
            ></IPOSItems>
          </div>
        </section>
        <Breaker></Breaker>
      </section>
    </>
  );
}
