import React from 'react';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import Footer from '../Footer/Footer';
import Header from '../Header/header';
import PageHeader from '../Page-Header/Page-Header';
import PageContent from '../Page-Header/PageContent';
import coverProject1 from "../Products/imgs/ARKHomes.png";
import coverProject3 from "../Products/imgs/opal.png";
import coverProject4 from "../Products/imgs/royal.png";
import coverProject5 from "../Products/imgs/masjid.png";
import coverProject6 from "../Products/imgs/BMC.png";

import coverProject2 from "../Products/imgs/UBS.png";

export default function pos() {
  return (
   
    <>
     <PageHeader
        title1="Our Portfolio"
        title2="Have a look at our projects"
       
        bgImg={'https://images.unsplash.com/photo-1591775667978-2222e699c77a?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTd8fG1vbml0b3J8ZW58MHx8MHx8&auto=format&fit=crop&w=600&q=60'}
      ></PageHeader>
      <PageContent servicename="portfolio"></PageContent>
      <section className='section-content'>
      <Container className='p-lg-5' data-aos='fade-up'>
        <Row className='g-4 mb-4 pe-2 ps-2'>
          <Col xs={12} sm={12} md={6} lg={4}  xl={4} >
            <Card className='productsCard'>
            <Card.Img src={coverProject1} alt="Card image" />

              <Card.Body className='mt-2'>
                <Card.Title className='card-title'>ARK Homes</Card.Title>
                <Card.Text>
                  ARKHomes is a Home Designer's company based in Australia. The logo and website of company are designed by our team.
                </Card.Text>
                <a href='https://arkhomes.com.au/' className="ms-2 btn btn-primary ">Read More</a>
              </Card.Body>
            </Card>
          </Col>
          <Col xs={12} sm={12} md={6} lg={4}  xl={4}>
            <Card className='productsCard'>
            <Card.Img src={coverProject2} alt="Card image" />
            <br></br>
              <Card.Body className='mt-4'>
                <Card.Title className='card-title'>United Business Services</Card.Title>
                <Card.Text>
                  UBS is an accounting firm in Australia. Its responsive website is designed and depoyed by Impact IT Solutions.
                </Card.Text>
                <a href='https://www.ubs-accounting.com.au/' className="ms-2 btn btn-primary ">Read More</a>
              </Card.Body>
            </Card>
          </Col>
          <Col xs={12} sm={12} md={6} lg={4}  xl={4}>
            <Card className='productsCard'>

            <Card.Img src={coverProject3} alt="Card image" />
              <Card.Body>
                <Card.Title className='card-title'>Opal Constructions</Card.Title>
                <Card.Text>
                Impact IT Solutions has produly developed the website of Opal constructions with social media pages and logo for the company.

                </Card.Text>
                <a href='https://opalssconstructions.com.au/' className="ms-2 btn btn-primary ">Read More</a>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row className='g-4 mb-4 pe-3 ps-3'>
          <Col xs={12} sm={12} md={6} lg={4}  xl={4}>
            <Card className='productsCard'>
            <Card.Img src={coverProject4} alt="Card image" />
              <Card.Body>
                <Card.Title className='card-title'>Royal Constructions</Card.Title>
                <Card.Text>
                  Impact IT Solutions has produly developed the website of Royal constructions with social media pages and logo for the company.
                </Card.Text>
                <a href='https://www.royal-constructions.com.au/' className="ms-2 btn btn-primary ">Read More</a>
              </Card.Body>
            </Card>
          </Col>
          <Col xs={12} sm={12} md={6} lg={4}  xl={4}>
            <Card className='productsCard'>
            <Card.Img src={coverProject5} alt="Card image" />
              <Card.Body>
                <Card.Title className='card-title'> Masjid Omar</Card.Title>
                <Card.Text>
                 The website of majid omar is proudly developed and deployed by Impact IT solutions
                </Card.Text>
                <a href='http://www.omarmosque.org.au/' className="ms-2 btn btn-primary ">Read More</a>
              </Card.Body>
            </Card>
          </Col>
          <Col xs={12} sm={12} md={6} lg={4}  xl={4}>
            <Card className='productsCard'>
              <Card.Img src={coverProject6} alt="Card image" />
              <Card.Body>
                <Card.Title className='card-title'>Bellambi Medical Centre</Card.Title>
                <Card.Text>
                  BMC is a medical center in Australia. Our web developers has designed responsive webiste for BMC.
                </Card.Text>
                <a href='#' className="ms-2 btn btn-primary ">Read More</a>
              </Card.Body>
            </Card>
          </Col>
          </Row>
        </Container>
        
        </section>
        </>
  );
}



 