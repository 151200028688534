import React from "react";
import PageHeader from "../Page-Header/Page-Header";
import PageContent from "../Page-Header/PageContent";
import "./services.css";
import { Link } from "react-router-dom";
import headerImg from "./imgs/engineering.png";
import DataScience from "./imgs/DataScience.svg";
import cleaning from "./imgs/cleaning.png";
import DA from "./imgs/DA.png";
import powerBI from "./imgs/powerBI.png";
import Breaker from "../Breaker";
export default function Branding() {
  return (
    <>
      <PageHeader
        title1="Data Science Services"
        title2="Smart IT Solutions for growing businesses."
        img={headerImg}
        bgImg={'https://images.unsplash.com/photo-1591775667978-2222e699c77a?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTd8fG1vbml0b3J8ZW58MHx8MHx8&auto=format&fit=crop&w=600&q=60'}

      ></PageHeader>
      <PageContent servicename="Data Science"></PageContent>
      {/*===================UPPER SECTION OF Data Science PAGE============== */}
      <section id="mobileAppPage" className="pt-5">
        <div className="mobileApp-upper">
          <div className="container">
            <p className="boldParagraph">
              Data is everywhere. At Impact IT Solutions our data analyst will
              help you in cleaning and making useful insights from your
              irganization data.
            </p>
          </div>
        </div>
        {/*===================HOW WE CAN HELP SECTION OF Data Science PAGE============== */}
        <div className="container pt-2">
          <div className="row">
            <div className="col-lg-6 col-12">
              <div className="container pt-2">
                <h3 className="sloganText pt-5 ">
                  {" "}
                  WE CAN HELP <span className="sloganImpt">IN</span>
                </h3>
              </div>
              <p
                className="boldParagraph  text-justify"
                style={{ fontSize: "22px", textAlign: "left" }}
              >
                Our experts in data analysis will help you in understanding your
                organizations data and making useful insights from it in the
                form of graph. We not only remove outliers from your data but
                also help you in choosing appropriate algorithm and technique
                according to your data type.
              </p>
            </div>
            <div className="col-lg-6 col-12">
              <img
                className="mobileChat"
                src={DataScience}
                alt="Mobile Application Image"
                width="70%"
              />
            </div>
          </div>
        </div>
        <br></br> <br></br>
        <p className="boldParagraph">Our Data-Science services include:</p>
        <div className="container mt-5 p-3">
          <div className="row d-flex justify-content-center">
            <div className="col-lg-3 col-md-4 mb-md-3 mb-lg-0">
              <div className="banner-features wow fadeIn animated hover-up animated animated">
                <img src={cleaning} alt="" width={100} />
                <br></br>
                <p className="paragraphText">Data Cleaning</p>
              </div>
            </div>
            <div className="col-lg-3 col-md-4 mb-md-3 mb-lg-0">
              <div className="banner-features wow fadeIn animated hover-up animated animated">
                <img src={powerBI} alt="" width={100} />
                <br></br>
                <p className="paragraphText">Data Visualization in power BI</p>
              </div>
            </div>
            <div className="col-lg-3 col-md-4 mb-md-3 mb-lg-0">
              <div className="banner-features wow fadeIn animated hover-up animated animated">
                <img src={DA} alt="" width={80} />
                <br></br>
                <p className="paragraphText">Data Analysis</p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Breaker></Breaker>
    </>
  );
}
