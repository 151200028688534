import React from "react";
import { Link } from "react-router-dom";
import breakerImg from "../assets/imgs/breaker.png";
export default function Breaker() {
  return (
    <section id="breaker" className="" data-aos="fade-up" >
      <div className=" footer-upper-section">
        <div className="container">
          <div className="row align-row footer-upper-section-row">
            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12  text-xl-left text-lg-left text-md-left text-sm-center  breaker-left">
              <p data-aos="fade">
                Have a project in mind?<br></br>
                Let's talk about it.
              </p>
              <Link
                to={process.env.PUBLIC_URL + "/" + "Contact/*"}
                className="d-flex justify-content-left btn"
              >
                CONTACT US
              </Link>
            </div>
            <div
              className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 footer-right-img wow animate__fadeInUp"
              data-wow-duration="1s"
              data-wow-delay="0.5s"
            >
              <img src={breakerImg}
              width="100%" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
