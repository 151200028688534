import React from "react";
import PageHeader from "../Page-Header/Page-Header";
import PageContent from "../Page-Header/PageContent";
import "./services.css";
import { Link } from "react-router-dom";

import Breaker from '../Breaker';
import headerImg from "./imgs/engineering.png";
export default function Software() {
  return (
    <>
      <PageHeader
        title1="Custom Software Development"
        title2="IT Solutions for growing businesses."
        img={headerImg}
        bgImg={'https://images.unsplash.com/photo-1591775667978-2222e699c77a?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTd8fG1vbml0b3J8ZW58MHx8MHx8&auto=format&fit=crop&w=600&q=60'}

      ></PageHeader>
      <PageContent servicename="Custom Software Development"></PageContent>
      <Breaker></Breaker>
      </>
  )
}