import React from "react";
import PageHeader from "../Page-Header/Page-Header";
import PageContent from "../Page-Header/PageContent";
import "./services.css";
import { Link } from "react-router-dom";
import webApp from "./imgs/WebApp.png";
import node from "./imgs/node.png";
import php from "./imgs/php.png";
import Mysql from "./imgs/mysql.png";
import mongo from "./imgs/mongo.png";
import ES6 from "./imgs/ES6.png";
import Tailwind from "./imgs/Tailwind.png";
import ReactJS from "./imgs/REACTjs.png";
import Bootstrap from "./imgs/Bootstrap.png";
import Breaker from '../Breaker';
import headerImg from "./imgs/engineering.png";
export default function WebApp() {
  return (
    <>
      <PageHeader
        title1="Web App Development"
        title2="IT Solutions for growing businesses."
        img={headerImg}
        bgImg={'https://images.unsplash.com/photo-1591775667978-2222e699c77a?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTd8fG1vbml0b3J8ZW58MHx8MHx8&auto=format&fit=crop&w=600&q=60'}

      ></PageHeader>
      <PageContent servicename="Web App Development"></PageContent>
      <section id="mobileAppPage" className="pt-5">
        <div className="mobileApp-upper">
          <div className="container">
            <p className="boldParagraph">
            We create easy to handle web applications for different kind of Businesses that run smooth on every screen size. Our clients from all around  the world trust our services in web development.

            </p>
          </div>
        </div>
         {/*===================HOW WE CAN HELP SECTION OF MOBILE APP DEVELOPMENT PAGE============== */}
        <div className="container pt-5">
          <div className="row">
            <div className="col-lg-6 col-12">
            <div className="container ">
      <h3 className="sloganText  ">HOW  <span className="sloganImpt">WE CAN HELP</span></h3>
    </div>
           <p className="boldParagraph  text-justify pt-2" style={{fontSize:"20.5px" , textAlign:"justify"}} >
           We use a wide range of technologies and frameworks to make responsive and robust web applications for different kind of Businesses. Our clients from all around  the world trust our services in web development.
           Our web development services includes:
          
           
           <ul className="ms-3 mt-3 text-justify">
            <li>Responsive Front-end</li>
            <li>Dashboard Support</li>
            <li>Web Content Writing</li>
            <li>Resuable and Clean Code</li>
           </ul>
           </p>
          
            </div>
            <div className="col-lg-6 col-12 pt-5">
              <img className="mobileChat" src={webApp} alt="web Application Image" />
            </div>
          </div>
        </div>
        {/*===========================web technologies section================================= */}
        <div className="container ">
      <h3 className="sloganText  ">FRONT END  <span className="sloganImpt">TECHNOLOGIES</span></h3>
    </div>
    <div className="container mt-5 p-3">
            <div className="row d-flex justify-content-center">
            <div className="col-lg-3 col-md-4 mb-md-3 mb-lg-0">
                        <div className="banner-features wow fadeIn animated hover-up animated animated" >
                            <img src={ReactJS} alt="" width={60}/>
                            <br></br><h4 className="bg-1 mt-3">React JS</h4>
                            </div>
                    </div>
                    <div className="col-lg-3 col-md-4 mb-md-3 mb-lg-0">
                        <div className="banner-features wow fadeIn animated hover-up animated animated" >
                            <img src={Tailwind} alt="" width={50}/>
                            <br></br><h4 className="bg-1 mt-3">Tailwind CSS</h4>
                           </div>
                    </div>
                    <div className="col-lg-3 col-md-4 mb-md-3 mb-lg-0">
                        <div className="banner-features " >
                            <img src={Bootstrap} alt="" width={90}/>
                            <br></br><h4 className="bg-1 mt-3">Bootstrap 5.2</h4>

                              </div>
                    </div>
                    <div className="col-lg-3 col-md-4 mb-md-3 mb-lg-0">
                        <div className="banner-features wow fadeIn animated hover-up animated animated" >
                            <img src={ES6} alt="" width={50}/>
                            <br></br><h4 className="bg-1 mt-3">Javascript ES6</h4>
                              </div>
                    </div>
                 
                  
            </div>
          </div>

    <div className="container ">
      <h3 className="sloganText  ">BACK END  <span className="sloganImpt">TECHNOLOGIES</span></h3>
    </div>
    <div className="container mt-5 p-3">
            <div className="row d-flex justify-content-center">
            <div className="col-lg-3 col-md-5 mb-md-3 mb-lg-0">
                        <div className="banner-features wow fadeIn animated hover-up animated animated" >
                            <img src={node} alt="" width={100}/>
                          
                            </div>
                    </div>
                    <div className="col-lg-3 col-md-5 mb-md-3 mb-lg-0">
                        <div className="banner-features wow fadeIn animated hover-up animated animated" >
                            <img src={php} alt="" width={120}/>
                         
                           </div>
                    </div>
                    <div className="col-lg-3 col-md-5 mb-md-3 mb-lg-0">
                        <div className="banner-features " >
                            <img src={Mysql} alt="" width={90}/>
                           

                              </div>
                    </div>
                    <div className="col-lg-3 col-md-5 mb-md-3 mb-lg-0">
                        <div className="banner-features " >
                            <img src={mongo} alt="" width={60}/>
                            
                              </div>
                    </div>
                 
                  
            </div>
          </div>
        </section>
      <Breaker></Breaker>
      </>
  )
}