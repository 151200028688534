import React from "react";
import "./PageContent.css";

import { Link } from "react-router-dom";
export default function PageContent(props) {
  return (
    <>
      <div className="bread-crumb-g">
        <div className="container">
          <ul id="breadcrumbs">
            <li>
              <Link to={process.env.PUBLIC_URL + '/' }>Home</Link>
            </li>
            <li className="separator"> / </li>
            <li>
              <strong> {props.servicename}</strong>
            </li>
          </ul>
        </div>
      </div>

      
    </>
  );
}
