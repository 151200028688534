import React from 'react'
import "./contact.css";
import PageHeader from "../Page-Header/Page-Header";
import PageContent from "../Page-Header/PageContent";
import office1 from "./imgs/office-1.jfif";
import office2 from "./imgs/office-2.jfif";
import location from "./imgs/location.svg";
import call from "./imgs/call.svg";
import headerimg from "./imgs/communicate.png";
import email from "./imgs/email.png";
import social from "./imgs/social.png";
export default function Contact() {
  return (
    <>
      <section id="contact">
        <PageHeader
          title1="Contact Us"
          title2="IT Solutions for growing businesses."
          img={headerimg}
          bgImg={'https://images.unsplash.com/photo-1534536281715-e28d76689b4d?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8NHx8Y29udGFjdCUyMHVzfGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=600&q=60'}
        >

        </PageHeader>
        <PageContent servicename="Contact us" ></PageContent>

      </section>
      <section id="contact-content">
        <section className="form-section">
          <div className="container">
            <div className="row">

              <div className="col-lg-6  col-12">
                <div className="left">
                  <h3>Let's talk ...</h3>
                  <p>Do you have any questions? Fill the form below to contact us. Our team will be happy to assist you.</p>
                  <div className="reader-response"><p></p>
                  </div>

                  <form action="/" method="post" className="contact-form">
                    <div className="row">
                      <div className="col-lg-6  col-12 mt-1" >
                        <div className="input-container">
                          <input type="text" name="name" className="form-control" id="name" placeholder="Your Name*" required />
                        </div>
                      </div>
                      <div className="col-lg-6 col-12 mt-1" >
                        <div className="input-container">
                          <input type="email" name="email" className="form-control" id="email" placeholder="Email*" required />
                        </div>
                      </div>

                      <div className="col-lg-6 col-12 mt-1" >
                        <div className="input-container">
                          <input type="text" name="phone-number" className="form-control" id="phone-number" placeholder="Phone Number*" required />
                        </div>
                      </div>
                      <div className="col-lg-6 col-12 mt-1" >
                        <div className="input-container">
                          <input type="text" className="form-control" name="subject" id="subject" placeholder="Subject" required />
                        </div>
                      </div>
                      <div className="col-lg-12 col-12 mt-1" >
                        <div className="input-container">
                          <textarea className="form-control" name="message" rows="5" placeholder="Your message about what you are looking for*" required></textarea>
                        </div>
                      </div>
                      <div className="col-lg-4 col-12 mt-3 d-flex justify-content-center " >
                        <div className="input-container">
                          <input type="submit" className=" form-control btn btn-outline-light send-btn " name="subject" id="subject" value="SEND" />
                        </div>
                      </div>
                    </div>
                  </form>

                </div>
              </div>

              <div className="col-lg-6 col-md-6 col-sm-8 col-xs-8">
                <div className="right">



                  <div className='container mt-3'>
                    <div className='d-flex justify-content-center '>
                      <div className='wrapper-icon '>
                        <img class="rounded-full" src={email} width={30} />
                      </div>
                      <div className='grow_text ms-2 mt-2'>
                        <p class=" mb-1" style={{ fontWeight: "700" }}>Email</p>
                        <p class=" mb-1" style={{
                          twTextOpacity: "1",
                          color: "rgb(107 114 128 / var(twTextOpacity))"
                        }}>support@iitsols.com</p>
                      </div>
                    </div>
                    <div className=' d-flex justify-content-center mt-3'>
                      <div className='wrapper-icon '>
                        <img class="rounded-full" src={social} width={30} />
                      </div>
                      <div className='grow_text ms-2 mt-3 '>

                        <ul className="social d-flex" style={{ listStyle: "none", marginLeft: "-32px" }}>
                          <li>
                            <a href="https://www.facebook.com/ImpactITSols">
                              <i className="fa-brands fa-facebook-f"></i>
                            </a>
                          </li>
                          <li>
                            <a href="https://www.linkedin.com/company/impact-it-sols/">
                              <i className="fa-brands fa-linkedin ms-2"></i>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>











                </div>
              </div>


            </div>
          </div>
        </section>
        <section id="cnt-office">
          <div className="container"  >
            <br></br>
            <article>
              {/* <h2 className='ms-4 mb-0'>Our Offices</h2> */}

            </article>
            <div className=' '>
              <div className="row g-0 p-0" >

                {/* <div className="col-lg-5 col-md-5 col-sm-6 mt-2 office_holder">
                  <div>  <div>
                    <div className="cnt-office">
                      <div className="card">

                        <div className="img1">

                          <img alt="mainOffice" src={office1}

                            width="150" height="70" />

                        </div>

                        <h3 className="main-text mt-2 pt-2">Australia Head Office</h3>

                        <ul >
                          <li className="d-flex align-items-center">
                            <img className="icon-images" src={location} width="50px" height="35px" />
                            <div className="mt-4 ms-1 align-items-center">
                              <p>74 Rowland Avenue, Wollongong NSW 2500</p>
                            </div>
                          </li>

                          <li className="d-flex align-items-center">

                            <img className="icon-images" src={call} width="50px" height="35px" />
                            <div className="mt-1 ms-1 align-items-center">
                              <p className="">+61 423 269 355</p>
                            </div>
                          </li>
                        </ul>

                      </div></div></div>
                  </div>
                </div>
                <div className="col-lg-5 col-md-5 col-sm-6 mt-2 office_holder">
                  <div >  <div>
                    <div className="cnt-office SecondOffice">
                      <div className="card">

                        <div className="img1">

                          <img alt="Qries" src={office2}

                            width="150" height="70" />

                        </div>



                        <h3 className="main-text mt-2 pt-2">Pakistan</h3>


                      </div></div></div>
                  </div>
                </div> */}
              </div>


            </div>
          </div>

        </section>
      </section>
    </>
  )
}
