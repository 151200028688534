import React from 'react';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import Footer from '../Footer/Footer';
import Header from '../Header/header';
import PageHeader from '../Page-Header/Page-Header';
import PageContent from '../Page-Header/PageContent';
import coverProduct2 from "../Products/imgs/payroll.png";
import coverProduct1 from "../Products/imgs/POS.jfif";
import coverProduct3 from  "../Products/imgs/LMS.png";
import coverProject2 from "../Products/imgs/UBS.png";
import { Link } from 'react-router-dom';

export default function pos() {
  return (
   
    <>
     <PageHeader
        title1="Our Products"
        title2="Have a look at our products"
       
        bgImg={'https://images.unsplash.com/photo-1591775667978-2222e699c77a?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTd8fG1vbml0b3J8ZW58MHx8MHx8&auto=format&fit=crop&w=600&q=60'}
      ></PageHeader>
      <PageContent servicename="Products"></PageContent>
      <div className=''>
     <section className='section-content'>
     <Container className='d-flex justify-content-center p-lg-5' data-aos='fade-up'>
        <Row className='g-4 mb-4 pe-2 ps-2'>
          <Col xs={12} sm={12} md={6} lg={4}  xl={4} >
            <Card className='productsCard'>
            <Card.Img src={coverProduct1} className="mt-2" alt="Card image"  />

              <Card.Body className=''>
                <Card.Title className='card-title'>Impact POS App</Card.Title>
                <Card.Text>
                  I-POS is a smart POS system for retail market. It provides facilities for cashier , stock management and high level admin tasks.
                </Card.Text>
                <Link to={ process.env.PUBLIC_URL + '/' + 'IPOS/*'} className="ms-2 btn btn-primary ">
                      Read More 
                    </Link>
              </Card.Body>
            </Card>
          </Col>
          <Col xs={12} sm={12} md={6} lg={4}  xl={4}>
            <Card className='productsCard'>
            <Card.Img src={coverProduct2} alt="Card image" />
            
            <Card.Body className=''>
                <Card.Title className='card-title'>Impact PayRoll App</Card.Title>
                <Card.Text>
                  I-Payroll is an online system for enterprises through which they can track real-time attendance and salary of employees.
                </Card.Text>
                <Link to={ process.env.PUBLIC_URL + '/' + 'IPayroll/*'} className="ms-2 btn btn-primary ">
                      Read More 
                    </Link>
              </Card.Body>
            </Card>
          </Col>
          <Col xs={12} sm={12} md={6} lg={4}  xl={4}>
            <Card className='productsCard'>

            <Card.Img src={coverProduct3} alt="Card image" />
              <Card.Body>
                <Card.Title className='card-title'>Learning Management System</Card.Title>
                <Card.Text>
                Impact IT Solutions presents LMS to give smooth learning experience to both ends. It has many options including staff attendence,user administration etc.
                </Card.Text>
                <Link to={ process.env.PUBLIC_URL + '/' + 'LMS/*'} className="ms-2 btn btn-primary ">
                      Read More 
                    </Link>
              </Card.Body>
            </Card>
          </Col>
        </Row>
       
        </Container>
     </section>
        
        </div>
        </>
  );
}



 