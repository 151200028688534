import React from 'react'

export default function IPOSItems(props) {
  return (
    <>
    <div
            class="solution-item-detail mt-2"
            id="partnercommunication"
            style={{ backgroundColor: props.clr}}
          >
            <div class="row">
              {" "}
              <div class="col-12 col-xl-4 " >
                <div id="left-sol" style= {{ backgroundImage:`url(${props.img})` , backgroundPosition:"center" , backgroundRepeat:"no-repeat"}}>
                  <div className='img-holder'>
                  
                  </div>
                
                </div>
              </div>
              <div class="col-12 col-xl-8 pl-xl-0">
                <div class="right-sol h-100">
                  <div class="position-relative vertical-centered-translated">
                    <h3 className=' sloganText d-flex justify-content-center mt-2' style={{color:"white" , fontSize : "28px"}}>{props.title}</h3>
                    <p className='paragraphText' style={{color:"white"}}>
                     {props.txt}
                    </p>
                  </div>{" "}
                </div>{" "}
              </div>{" "}
            </div>
          </div>

    </>
  )
}
