import React from "react";
import PageHeader from "../../Page-Header/Page-Header";
import PageContent from "../../Page-Header/PageContent";
import Breaker from "../../Breaker";
import coverProduct1 from "../imgs/LMS1.png"
export default function LMS() {
  return (
    <>
      <section id="LMS-section">
        <PageHeader
          title1="Learning Management System"
          bgImg={
            "https://images.unsplash.com/photo-1591775667978-2222e699c77a?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTd8fG1vbml0b3J8ZW58MHx8MHx8&auto=format&fit=crop&w=600&q=60"
          }
        ></PageHeader>
        <PageContent servicename="Products"></PageContent>
        <section id="mobileAppPage" className="pt-5">
          <div className="mobileApp-upper">
            <div className="container">
              <p className="boldParagraph">
              A learning management system (LMS) is a software application or web-based technology that is used to plan, implement, and assess a specific learning process.
             We provide a secure LMS which will protect the details and reputation of your certification amd porgram
              </p>
            </div>
          </div>
          {/*===================HOW WE CAN HELP SECTION OF MOBILE APP DEVELOPMENT PAGE============== */}
          <div className="container pt-5">
            <div className="row">
              <div className="col-lg-6 col-12">
                <div className="container ">
                  <h3 className="sloganText  ">
                    Main  <span className="sloganImpt">Features</span>
                  </h3>
                </div>
                <p
                  className="boldParagraph   pt-2"
                  style={{ fontSize: "18px",  }}
                >
Main features of a LMS are mentioned below:                  <ul className="ms-3 mt-3 text-justify">
                    <li><strong>Admin Side</strong></li>
                   <ol className="ms-1 mt-2">
                   <li >Course creation</li>
                    <li >Video conferencing</li>
                    <li >Student Performance Tracker</li>
                    <li >Offline learning trackers</li>
                   <li>Course registration and delivery</li>
<li>Tracking and analyzing user data</li>
                   </ol>
                  </ul>
                  <ul className="ms-3 mt-3 text-justify">
                  <li><strong>Student Side</strong></li>
                   <ol className="ms-1 mt-2">
                   <li>Tracking Progress </li>
                   <li>Notifications for new lectures</li>
                   <li>Notifications for assignments and quizes</li>
                  
                   </ol>
                   
                  </ul>
                </p>
              </div>
              <div className="col-lg-6 col-12 pt-5">
                <img
                  className="mobileChat pt-5"
                  src={coverProduct1}
                  width="100%"
                  alt="payroll management software image"
                />
              </div>
            </div>
          </div>
    
         
          
          
        </section>
        <Breaker></Breaker>
      </section>
    </>
  );
}
