import React from "react";
import p1 from "./imgs/ROYAL.png";
import p2 from "./imgs/OM.png";
import p3 from "./imgs/BMC.png";
import p4 from "./imgs/Opal.png";
import p5 from "./imgs/UBS.png";
import "./partners.css";
export default function partners() {
  return (
    <>
      <section id="partners"  data-aos="fade-up">
        <div className="section-heading  ">
          <h4 className="title_block">OUR CLIENTS</h4>
        </div>
        <section
          id="clients"
          className="clients section-bg d-flex justify-content-center"
        >
          <div className="container">
            <div className="row d-flex justify-content-center">
              <div
                className="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center"
                data-aos="zoom-in"
              >
                <img src={p1} className="img-fluid" alt="" />
              </div>

              <div
                className="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center"
                data-aos="zoom-in"
              >
                <img src={p2} className="img-fluid" alt="" />
              </div>

              <div
                className="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center"
                data-aos="zoom-in"
              >
                <img src={p3} className="img-fluid" alt="" />
              </div>

              <div
                className="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center"
                data-aos="zoom-in"
              >
                <img src={p4} className="img-fluid" alt="" />
              </div>
              <div
                className="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center"
                data-aos="zoom-in"
              >
                <img src={p5} className="img-fluid" alt="" />
              </div>
            </div>
          </div>
        </section>
      </section>
    </>
  );
}
