import React from "react";
import "./footer.css";
import logo from "../../assets/imgs/logo.png";
import { Link } from "react-router-dom";
export default function Footer() {
  return (
    <>
      <footer id="footer" data-aos="fade">
        <div className="footer-content">
          <div className="container">
            <div className="row foot">
              <div className=" col-lg-3 col-md-6 col-sm-12 col-xs-6">
                <h3 className="d-flex justify-content-start mb-3">
                  GET IN TOUCH
                </h3>
                <div className="column">
                  <ul>
                    <li>
                      <span className="">

                        <i className="fa-solid fa-location-dot"></i>
                      </span>
                      <span className="ms-2 ">
                        Suite 5, 5-7 Littleton Street, Riverwood NSW 2210
                      </span>
                    </li>
                    {/* <hr></hr>
                    <li>
                      <span className="d-inline-block">
                        
                        <i className="fa-solid fa-location-dot"></i>
                      </span>
                      <span className="ms-2">
                        5th Floor, 42-A Westeria Road, DHA Phase II, Islamabad
                      </span>
                    </li>
                    <hr></hr>
                    <li>
                      <span className="d-inline-block">
                        
                        <i className="fa-solid fa-phone fa-1x"></i>
                      </span>
                      <span className="d-inline-block ms-2">
                        +92 51 5147126
                      </span>
                    </li> */}
                    <hr></hr>
                    <li>
                      <span className="d-inline-block">

                        <i className="fa-solid fa-envelope"></i>
                      </span>
                      <span className="d-inline-block ms-2">
                        support@iitsols.com
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-sm-12 col-xs-6 ">
                <h3>Important Links</h3>
                <ul>
                  <li>
                    <Link
                      to={process.env.PUBLIC_URL + "/"}
                      className="nav-link "
                      aria-current="page"
                    >
                      Home
                    </Link>
                  </li>

                  <li>
                    <Link
                      to={process.env.PUBLIC_URL + "/" + "AboutUs/*"}
                      className="nav-link "
                      aria-current="page"
                    >
                      About us
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={process.env.PUBLIC_URL + "/" + "Contact/*"}
                      className="nav-link "
                      aria-current="page"
                    >
                      Contact us
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={process.env.PUBLIC_URL + "/" + "OurServices/*"}
                      className="nav-link "
                      aria-current="page"
                    >
                      Our Services
                    </Link>
                  </li>

                </ul>
              </div>
              <div className="col-lg-3 col-md-6 col-sm-12 col-xs-6 ps-2">
                <h3>Services</h3>
                <ul>

                  <li>
                    <Link to={process.env.PUBLIC_URL + "/" + "Cyber/*"} className="nav-link " aria-current="page">
                      Cyber Security Solutions
                    </Link>
                  </li>
                  <li>
                    <Link to={process.env.PUBLIC_URL + "/" + "DataScience/*"} className="nav-link " aria-current="page">
                      AI & DataScience
                    </Link>
                  </li>
                  <li>
                    <Link to={process.env.PUBLIC_URL + "/" + "WebApp/*"} className="nav-link " aria-current="page">
                      Web Development
                    </Link>
                  </li>
                  <li>
                    <Link to={process.env.PUBLIC_URL + "/" + "MobileApp/*"} className="nav-link " aria-current="page">
                      Mobile App Development
                    </Link>
                  </li>
                  <li>
                    <Link to={process.env.PUBLIC_URL + "/" + "SEO/*"} className="nav-link " aria-current="page">
                      SEO & Digital Marketing
                    </Link>
                  </li>

                </ul>
              </div>
              <div className="col-lg-3 col-md-6 col-sm-12 col-xs-6 ps-2">
                <h3>OUR PRODUCTS</h3>
                <ul>

                  <li>
                    <Link to={process.env.PUBLIC_URL + "/" + "IPOS/*"} className="nav-link " aria-current="page">
                      Impact Point of Sale
                    </Link>
                  </li>
                  <li>
                    <Link to={process.env.PUBLIC_URL + "/" + "IPayroll/*"} className="nav-link " aria-current="page">
                      Impact Pay-Roll App
                    </Link>
                  </li>
                  <li>
                    <Link to={process.env.PUBLIC_URL + "/" + "LMS/*"} className="nav-link " aria-current="page">
                      Impact LMS
                    </Link>
                  </li>


                </ul>
              </div>
            </div>
          </div>

          <div className=" copyright flex flex-column">
            <div className="row ">
              <div className="col-12 d-flex justify-content-center">
                <a href="#" className="pb-2">
                  <img src={logo} className="d-flex justify-content-center" />
                </a>
              </div>
            </div>
            <p>
              Copyright &copy;2023 | Impact IT Solutions | All Rights Reserved
            </p>
          </div>
        </div>
      </footer>
    </>
  );
}
