import React from "react";
import PageHeader from "../Page-Header/Page-Header";
import PageContent from "../Page-Header/PageContent";
import team from "./overlappingSection/imgs/team.jpg";
import leader from "./overlappingSection/imgs/leader.jpg";
import saad from "./imgs/saad.jpg";
import azeem from "./imgs/azeem.png";
import aqsa from "./imgs/aqsa1.jpeg";
import shinza from "./imgs/shinza.png";
import esha from "./imgs/esha.png";

import ali from "./imgs/ali.png";
import altaf from "./imgs/altaf.png";

import aatiqa from "./imgs/aatiqa.png";

import roman from "./imgs/roman.png";

import "./about.css";

import headerimg from "./imgs/information.png";
import projectImg from "./imgs/project.png";
import expImg from "./imgs/experience.png";
import clients from "./imgs/clients.png";
import OverlappingSection from "./overlappingSection/index";
import Breaker from "../Breaker";
export default function AboutMain() {
  return (
    <>
      <PageHeader
        title1="About Us"
        title2="IT Solutions for growing businesses."
        img={headerimg}
        bgImg={'https://images.unsplash.com/photo-1462826303086-329426d1aef5?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTJ8fGFib3V0fGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=600&q=60'}
      >

      </PageHeader>

      <PageContent servicename="About us" ></PageContent>

      <section id="aboutMain">
        <div className="container">
          <h3 className="sloganText ">An IT Company you can <span className="sloganImpt">Trust</span></h3>
        </div>
        <div className="container">
          <p className="paragraphText" >
            Impact IT Solutions is an IT Company which was established in 2017 and since then it has been providing IT Services to different kind of businesses across the globe. Our dedicated team of Software Engineers is always ready to hear your Business problems and porvide solutions accordingly.
          </p>
        </div>

        {/* <div className="container">
      <div className="row">
        <div className="col-lg-4 ">
        <div className="achievements_holder ">
          <h3>9 Years </h3>
          <p>Industry Experience</p>
          <div className="achivImg">
          <img src={expImg} alt="experience Image"/>
        </div>
        </div>
        </div>

        <div className="col-lg-4">
        <div className="achievements_holder">
          <h3>10+ </h3>
          <p>completed projects</p>
          <div className="achivImg">
          <img src={projectImg} alt=" Completed Projects Image"/>
        </div>
        </div>
          </div>
          <div className="col-lg-4">
          <div className="achievements_holder">
          <h3>50+ </h3>
          <p>Happy Clients</p>
          <div className="achivImg">
          <img src={clients} alt=" Happy client Image"/>
          </div>
          </div>
          </div>
      </div>
    </div> */}
        <br></br>  <br></br>

        <div className="container">
          <h3 className="sloganText ">OUR  <span className="sloganImpt">MISSION</span></h3>
        </div>
        <p className="paragraphText d-flex justify-content-center " style={{ fontFamily: "Mulish", fontWeight: "700", fontSize: "1.25rem" }}>
          To provide solutions for your Business needs through Technology.
        </p>
        <div className="container">
          <p className="paragraphText  ">
            The team at Impact IT Solutions is equipped with trending IT skills to bring the best posible solutions according to customer reuqirements.
            We strive to offer various kind of IT services under one roof. The security , relaiblity and quality of our products is what makes us <strong>" The Impact IT Solutions".</strong>
          </p>
        </div>
        <div className="container">
          <h3 className="sloganText ">OUR  <span className="sloganImpt">VISION</span></h3>
        </div>

        <div className="container">
          <p className="paragraphText ">
            Our vision is to be a customer-centric IT Company where customers can find solutions for managing their Business needs.
            We believe that sticking to the outdated technology for years can bring many issues and security vulnerabilities in the systems.
            We keep ourself up-to-date with technology trends and adopt them to boost the performance.</p>
        </div>
        <div className="container">
          <h3 className="sloganText ">OUR  <span className="sloganImpt">VALUES</span></h3>
        </div>
        <div className="container p-5">
          <div className="row g-2  d-flex justify-content-center ">


            <div className="col-lg-4 col-md-6 col-sm-6 col-xs-12 mb-4 mb-lg-0">
              <div className="card teamCard">
                <h3>Quality</h3>
                <p className="paragraphText mt-4">
                  We at Impact try our best to maintain high standards in our products and services. Our services are of high quality, reliable and long lasting.
                </p>

              </div>
            </div>

            <div className="col-lg-4 col-md-6 col-sm-6 col-xs-12 mb-4 mb-lg-0">
              <div className="card teamCard mt-1 ">
                <h3>TEAMWORK</h3>
                <p className="paragraphText">Better results are obtained through collaborative problem solving. In a simple terms, teams improve productivity. By keeping this in mind we at Impact IT solution work as a team to solve every Business problem using Technology.</p>
              </div>
            </div>

          </div>
          <div className="row g-2  d-flex justify-content-center  mt-2">

            <div className="col-lg-4 col-md-6 col-sm-6 col-xs-12 mb-4 mb-lg-0">
              <div className="card teamCard ">
                <h3 className="mt-3">RESPECT</h3>
                <p className="paragraphText mt-1">We beleive that mutual respect is the key in every kind of Business and it creates a fair work enviornment . We respect our people , our customers and our environment. </p>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 col-sm-6 col-xs-12 mb-4 mb-lg-0">
              <div className="card teamCard ">
                <h3>HONESTY</h3>
                <p className="paragraphText">We beleive in building lasting relationships with our customers and for that we don't try to be something which we are not. We keep the promises that we provides transparency to our clients in their work.</p>
              </div>
            </div>
          </div>

        </div>
        <div className="container mt-5">
          <h3 className="sloganText mt-5 ">OUR <span className="sloganImpt">TEAM </span></h3>
        </div>
        <div className="container">
          <p className="paragraphText  d-flex justify-content-center">
            We have a skilled team with a broad range of expertise. We handle every phase of the software development cycle, including project management, business analysis, software architecture, design, development, and testing.
          </p>
        </div>
        <div className="container p-5">
          <div className="row g-0  d-flex justify-content-center ">
            <div className="col-lg-4 col-md-6 col-sm-6 col-xs-12 mb-4 mb-lg-0">
              <div className="card teamCard">
                <div className="teamImg">
                  <img src={ali} alt="Team member picture" className="" />

                </div>
                <p>Ali Hassan Abbasi</p>
                <h3>SOFTWARE TEAM LEAD</h3>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-6 col-xs-12 mb-4 mb-lg-0">
              <div className="card teamCard">
                <div className="teamImg">
                  <img src={azeem} alt="Team member picture" className="" />
                </div>
                <p>Muhammad Azeem</p>
                <h3>Full Stack Developer</h3>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-6 col-xs-12 mb-4 mb-lg-0">
              <div className="card teamCard">
                <div className="teamImg">
                  <img src={aatiqa} alt="Team member picture" className="" />
                </div>
                <p>Aatiqa Ghazali</p>
                <h3>Web Developer</h3>
              </div>
            </div>
          </div>
          <div className="row g-4 mt-2 d-flex justify-content-center">
            <div className="col-lg-4 col-md-6 col-sm-6 col-xs-12 mb-4 mb-lg-0">
              <div className="card teamCard">
                <div className="teamImg">
                  <img src={roman} alt="Team member picture" className="" />
                </div>
                <p>Roman Qazi</p>
                <h3>Junior Frontend Developer</h3>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-6 col-xs-12 mb-4 mb-lg-0">
              <div className="card teamCard">
                <div className="teamImg">
                  <img src={aqsa} alt="Team member picture" className="" />
                </div>
                <p>Aqsa Razzaq</p>
                <h3>FRONTEND DEVELOPER</h3>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-6 col-xs-12 mb-4 mb-lg-0">
              <div className="card teamCard">
                <div className="teamImg">
                  <img src={esha} alt="Team member picture" className="" />
                </div>
                <p>Mahnoor Esha</p>
                <h3>UI/UX Designer</h3>
              </div>
            </div>
          </div>
          <div className="row g-4 mt-2 d-flex justify-content-center">
            <div className="col-lg-4 col-md-6 col-sm-6 col-xs-12 mb-4 mb-lg-0">
              <div className="card teamCard">
                <div className="teamImg">
                  <img src={shinza} alt="Team member picture" className="" />
                </div>
                <p>Shinza Gul</p>
                <h3>SQA Engineer</h3>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-6 col-xs-12 mb-4 mb-lg-0">
              <div className="card teamCard">
                <div className="teamImg">
                  <img src={altaf} alt="Team member picture" className="" />
                </div>
                <p>Altaf Hussain</p>
                <h3>React Developer</h3>
              </div>
            </div>
          </div>
        </div>

        {/*
    <OverlappingSection head="Leadership" img="https://images.unsplash.com/photo-1489410342162-b4b300a0bb15?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1yZWxhdGVkfDN8fHxlbnwwfHx8fA%3D%3D&auto=format&fit=crop&w=600&q=60"></OverlappingSection>
    <div className="container">
      <p className="paragraphText">
      Neque porro quisquam est qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit.Neque porro quisquam est qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit.
      Neque porro quisquam est qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit.Neque porro quisquam est qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit.

      </p>
      <div className="container  ms-5 me-5">
      <div className="row g-0  ">
        <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12 mb-4 mb-lg-0">
          <div className="teamImg bg-image hover-overlay ripple shadow-1-strong rounded"
          data-ripple-color="light">
            <figure>
          <img src={azeem} alt="Team member picture" className="w-75 rounded"/>
          <figcaption className="d-flex justify-content-center mt-2 mb-2 w-75" >CEO : Dr Shams uddin Qazi</figcaption>
          </figure>
        </div>
        </div>
        <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12 mb-4 mb-lg-0">
          <div className="teamImg bg-image hover-overlay ripple shadow-1-strong rounded"
          data-ripple-color="light">
            <figure>
          <img src={roman} alt="Team member picture" className="w-75 rounded"/>
          <figcaption className="d-flex justify-content-center mt-2 mb-2 w-75" >Project Manager : Engineer Shujah Qazi</figcaption>
          </figure>
        </div>
        </div>
        </div></div>
    </div>*/}

      </section>
      <Breaker></Breaker>
    </>
  );
}
