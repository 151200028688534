import React from "react";
import "./page-header.css";
import cube from "./imgs/network (2).png";
import network from "./imgs/net.png";
export default function PageHeader(props) {
  
  return (
    <>
      <section id="top-header-pages" style= {{ backgroundImage:`url(${props.bgImg})` , backgroundPosition:"center" , backgroundRepeat:"no-repeat"}} >
        <div className="container " />
        <div className="row">
         
          <div className="col-lg-12 ">
          
          </div>
        </div>
       
        <div className="h-100 d-flex flex-column justify-content-center  ">
          <div className="row ">
            <div className="col-lg-6 mt-5">
            
          <div className="top-header-g-content h-100  ">
            <h1>{props.title1}</h1>
            <h2>{props.title2}</h2>
            </div>
            </div>
            <div className="col-lg-6 d-flex justify-content-center mt-5 mb-1">
          <div className="top-header-g-content ">
          <div className="header-cuber d-flex  justify-content-center">
        <img src={network} className="left-cube" width={50} ></img>
        
        </div>
        
        <div className="header-cuber d-flex  justify-content-center">
        <img src={network} className="left-cube  "  width={50}></img>
        <img src={network} className="left-cube  "  width={20}></img>
        
        </div>
            </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
