export const data=
[
    {
        txt : "Self ordering system can be used to place orders by yourself. The interface of IPOS self ordering module is kept simple so that the users can place their orders with just a few clicks. This feature can lessen the  waiting time for customers and also removes the burden from waitors. Moreover, all orders are sent directly to the kitchen so the chances of order being lost or missed will be minimized."
    },
    {
        txt : "IPOS supports multiple types of leading payments methods which are used worldwide. The payment process is secured using state of the art authentication techniques and it allows the data to flow smoothly and securely from one platform to other "
    },
    {
        txt : "IPOS has a built in smart recommendation sytem which suggests products to customers based on various factors and analysis. It engages shoppers, reduce workload and can increase the sales. In short this smart feature which is  built on the top of strong data mining algorithms can convert random shoppers to your regular customers."
    },
    {
        txt : "Our user friednly CRM in IPOS provides functionality to interect with customers by offerring details of promotional items to them. I also indludes the funtionality to keep record of regular customers and provides them various items on discounted prices.  "
    },
    {
        txt : "A separte UI is designed in IPOS for kitchen display system which will be used by cooks/chefs in the kitchen. The kitchen staff can login to thier panels to view and update information about orders. They can update order status between pending, in the oven , cooked and delivered."
    },
    {
        txt : "This feature of IPOS keeps track of inventroy amd control what's being sold with the functionalitoy of low stock alerts. Effective stock controlling in IPOS is usefull to avoid out of stock situations and bad customer experience  "
    },
    {
        txt : "This advance module of IPOS generates real time reports from data of different types such as orders , sales ,stock amd employee details. It will help the admin to view profit and loss in the form of graphs and reports and provides summary of different activities. This process of reports generation in IPOS is smooth and can be updated at run-time."
    },
];